<template>
	<div class="orders">
		<div class="list">
			<div class="tools">
				<v-btn color="primary" rounded @click="startNewOrder">New Order</v-btn>
			</div>
			<v-list>
				<v-list-item class="order-item" v-for="o in ordersList" :key="o.order.userID"
						@click="changeSelectedOrder(orders[o.order.orderID])">
					<div class="date">{{ o.order.dateLocal }}</div>
					<div class="sub">{{ o.order.invoiceNum }}</div>
					<!--          <div class="sub">{{ o.order.grandTotal ? '$' + formatMoney(o.order.grandTotal) : '' }}</div>-->
				</v-list-item>
			</v-list>
		</div>
		<div class="form" v-if="hasSelectedOrder">
			<v-card
					class="order-card"
					v-if="newOrder"
			>

				<div class="order-card__container">

					<div class="section-title section-title--small">To:</div>
					<div class="field" v-for="(line, i) in selectedOrder.to" :key="`${i}line`">
						<v-text-field dense :label="`line: ${i + 1}`" v-model="selectedOrder.to[i]"/>
						<v-icon small class="remove fal fa-times" @click="removeFromArray(selectedOrder.to, i)"/>
					</div>
					<v-btn color="primary add-btn" x-small rounded @click="addToLine">Add line</v-btn>

					<div class="section-title section-title--small">What:</div>
					<div class="field" v-for="(item, ii) in selectedOrder.items" :key="item.id + ii + 'item'">
						<v-select label="Choose Product" :disabled="!newOrder" :items="possibleProducts" dense
								:value="selectedOrder.items[ii].id"
								@input="changeOrderItemType(ii, $event)"/>
						<v-text-field label="Quantity" :disabled="!newOrder" type="number" class="count" dense
								v-model="selectedOrder.items[ii].count"/>
						<v-icon v-if="newOrder" small class="remove fal fa-times"
								@click="removeFromArray(selectedOrder.items, ii)"/>
					</div>
					<v-btn v-if="newOrder" color="primary" class="add-item add-btn" x-small rounded @click="addItem">Add Item
					</v-btn>

					<div class="section-title section-title--small">Shipping:</div>
					<v-btn-toggle>Toggle</v-btn-toggle>
					<v-radio-group v-model="radioShipping">
						<v-radio
								v-for="(shipping, key) in shippingPrices"
								:key="key"
								:label="shipping.text"
								:value="key"
						/>
					</v-radio-group>


					<div class="section-title section-title--small">Email</div>
					<div class="field">
						<v-text-field label="Email to send to" :disabled="!newOrder" type="text" class="email" dense
								v-model="selectedOrder.email"/>
					</div>

					<div class="section-title section-title--small">Notes</div>
					<div class="field">
						<v-text-field label="Special Instructions:" :disabled="!newOrder" type="text" class="email" dense
								v-model="selectedOrder.internalNotes"/>
					</div>

				</div>

				<v-card-actions v-if="newOrder" class="order-actions">
					<v-btn color="success" :disabled="!isValidNewOrder" @click="saveOrder">Submit Order</v-btn>
					<v-btn color="error" @click="startNewOrder">Reset</v-btn>
				</v-card-actions>
				<div class="submit-info">
					Submitted order will use payment info on file. When order is finalized you will receive a confirmation email
					with shipping and tracking information.
				</div>
			</v-card>


			<div class="itemized">
				<h3>{{ newOrder ? 'New Order' : `Invoice Number: ${selectedOrder.invoiceNum}` }}</h3>
				<div class="to">
					<div class="to-item" v-for="(line, i) in selectedOrder.to" :key="i">{{ line }}</div>
					<div class="date">Date: {{ selectedOrder.dateLocal }}</div>
					<div class="email">{{selectedOrder.email}}</div>
					<div class="internalNotes">{{ selectedOrder.internalNotes }}</div>
				</div>
				<table>
					<tr>
						<th>Description</th>
						<th>Quantity</th>
						<th>Unit Price</th>
						<th>Amount</th>
					</tr>
					<tr v-for="(item, iii) in selectedOrder.items" :key="item.text + iii">
						<td>{{ item.text }}</td>
						<td>{{ item.count }}</td>
						<td>
							<div class="money"><span class="sym">$</span>{{ formatMoney(item.unitPrice) }}</div>
						</td>
						<td>
							<div class="money"><span class="sym">$</span>{{
									(((item.count || 1)) * (item.unitPrice || 0)) ? formatMoney(((item.count || 1)) * (item.unitPrice || 0)) : 0
								}}
							</div>
						</td>
					</tr>
					<tr class="break blank">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td>SubTotal</td>
						<td>
							<div class="money"><span class="sym">$</span>{{ formatMoney(selectedOrder.subTotal) }}</div>
						</td>
					</tr>
					<tr class="break">
						<td class="right smaller">{{ selectedOrder.terms }}</td>
						<td></td>
						<td></td>
						<td>
							<div class="money neg"><span class="sym">$</span>{{ formatMoney(selectedOrder.rebate) }}</div>
						</td>
					</tr>
					<tr class="break">
						<td class="right">{{ selectedOrder.shipping.description }}</td>
						<td></td>
						<td></td>
						<td>
							<div class="money"><span
									class="sym">$</span>{{ selectedOrder.shipping.price ? formatMoney(selectedOrder.shipping.price) : 0 }}
							</div>
						</td>
					</tr>
					<tr class="break">
						<td></td>
						<td></td>
						<td>Total</td>
						<td>
							<div class="money"><span class="sym">$</span>{{ formatMoney(selectedOrder.grandTotal) }}</div>
						</td>
					</tr>
				</table>
			</div>

		</div>
	</div>
</template>

<script>
import { parse } from 'date-fns';
import { uuid } from "@/helpers/uuid";
import { analytics, db } from "@/helpers/firebase";
import { store } from "@/helpers/store";
import { pricing } from "@/components/pricing";

export default {
	name: 'PrivateOrders',
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'EMS Bio Orders',
		meta: [
			{
				name: 'description',
				content: 'Place new orders and see previous ones.'
			},
		],
		// all titles will be injected into this template
		titleTemplate: '%s'
	},
	props: {},
	components: {},
	data() {
		return {
			user: {},
			orders: {},
			newOrderIDs: {}, // track if the order being saved is new
			companies: {},
			selectedOrder: {},
			radioShipping: '2Day', // see below for options
			shippingPrices: {
				'1Day': {
					text: 'Next Day Shipping',
					value: 30,
				},
				'2Day': {
					text: 'Two Day Shipping',
					value: 20,
				},
			},
			rebatePerCC: 1000,
			pricing,
		};
	},
	beforeDestroy() {
		store.unwatch('systemUser', this.setUser);
	},
	created() {
		store.watch('systemUser', this.setUser);
		this.setUser(store.get('systemUser'));

		if (this.user?.data) {
			this.getInitData();
		}

	},
	methods: {
		calcShippingPrice(symbol) {
			const newPrice = this.shippingPrices[symbol] || 20;
			this.$set(this.selectedOrder.shipping, 'price', newPrice.value);
			this.$set(this.selectedOrder.shipping, 'description', newPrice.text);
		},
		calculateOrderTo(e) {
			const to = [];
			const company = this.companies[e]
			if (company.practiceContactName) to.push(`Attention: ${company.practiceContactName}`);
			if (company.practiceName) to.push(company.practiceName);
			if (company.practiceStreet) to.push(company.practiceStreet);
			if (company.practiceCity || company.practiceStreet || company.practiceZip) {
				const str = `${company.practiceCity + ', '}${company.practiceState} ${company.practiceZip}`;
				to.push(str);
			}

			return to;
		},
		addToLine() {
			this.selectedOrder.to.push('');
		},
		changeSelectedOrder(order) {
			this.$set(this, 'selectedOrder', order);
		},
		getInitData() {
			// only try and get it once.
			if (Object.keys(this.orders).length) return;

			db.collection("companies")
					.doc(this.user.data.companyID)
					.get()
					.then(doc => {
						if (doc.exists) {
							this.$set(this.companies, doc.id, doc.data())
						} else {
							console.error('no company');
						}
					})
					.catch(function(error) {
						console.log("Error getting orders: ", error);
					});

			db.collection("orders")
					.where("companyID", "==", this.user.data.companyID)
					.get()
					.then(querySnapshot => {
						querySnapshot.forEach(doc => {
							// doc.data() is never undefined for query doc snapshots
							this.$set(this.orders, doc.id, doc.data());
						});
					})
					.catch(function(error) {
						console.log("Error getting orders: ", error);
					});
		},
		// comes from the store so watch for .new
		setUser(user) {
			this.$set(this, 'user', user.new || user);
		},
		calculateItems() {
			const toReturn = {
				subTotal: 0,
				grandTotal: 0,
				rebate: 0,
				totalCC: 0,
			};

			this.selectedOrder.items.forEach(item => {
				const cost = (item.count || 1) * (item.unitPrice || 0);
				// toReturn.itemsByCost.push(Object.assign({}, item, {
				//   unitPriceFormatted: item.unitPrice ? this.formatMoney(item.unitPrice) : 0,
				//   cost: cost ? this.formatMoney(cost) : 0
				// }));

				toReturn.totalCC += (item.cc * item.count);

				toReturn.subTotal += cost;
			})

			toReturn.rebate = (toReturn.totalCC * this.rebatePerCC) || 0;

			toReturn.grandTotal = toReturn.subTotal - toReturn.rebate + (this.selectedOrder?.shipping?.price || 0);

			Object.keys(toReturn).forEach(key => {
				this.$set(this.selectedOrder, key, toReturn[key]);
			})


		},
		saveOrder() {
			db.collection('orders')
					.doc(this.selectedOrder.orderID)
					.set(this.selectedOrder)
					.then(() => {
						this.$set(this.orders, this.selectedOrder.orderID, this.selectedOrder);
						if (this.newOrderIDs[this.selectedOrder.orderID]) {
							delete this.newOrderIDs[this.selectedOrder.orderID];
							analytics.logEvent('new order created');
						}
						this.$set(this, 'newOrderIDs', {});
					});
		},
		formatMoney(num) {
			if (!num) return 0;
			return Number((num || 1)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
		},
		addItem() {
			this.selectedOrder.items.push({
				count: 1,
			})
		},
		changeOrderItemType(index, e) {
      Object.keys(this.pricing[e]).forEach(key => {
        this.$set(this.selectedOrder.items[index], key, this.pricing[e][key]);
      })
			this.$set(this.selectedOrder.items[index], 'id', e);
		},
		setOrderDate(theDate) {
			const date = parse(theDate, 'yyyy-MM-dd', new Date());
			this.selectedOrder.date = date;
			this.selectedOrder.dateUTC = date.toISOString();
			this.selectedOrder.dateLocal = date.toLocaleString().substr(0, 10);
			if (this.selectedOrder.dateLocal.endsWith(',')) {
				this.selectedOrder.dateLocal = this.selectedOrder.dateLocal.slice(0, -1);
			}
		},
		removeFromArray(array, i) {
			array.splice(i, 1);
		},
		startNewOrder() {
			const id = uuid();
			this.newOrderIDs[id] = true;

			this.$set(this, 'selectedOrder', {
				companyID: this.user.data.companyID,
				date: new Date(),
				dateUTC: new Date().toISOString(),
				dateLocal: new Date().toLocaleDateString(),
				dueDate: new Date(),
				dueDateUTC: new Date().toISOString(),
				dueDateLocal: new Date().toLocaleDateString(),
				invoiceNum: `ems-${new Date().getTime()}`,
				orderID: id,
				to: this.calculateOrderTo(this.user.data.companyID),
				items: [
					{
						count: 1,
					}
				],
				shipping: {
					description: 'Shipping (2 day)',
					price: 20,
				},
				rebate: 0,
				email: this.user.data.email,
				internalNotes: '',
				terms: '50% w/ order, 50% minus credits net 45 day terms',
			});
		}
	},
	computed: {
		companyName() {
			return this.companies[this.user.data.companyID].practiceName;
		},
		isValidNewOrder() {
			if (!this.selectedOrder.items) return false;
			return this.selectedOrder.items.every(item => {
				return item.id && (item.count > 0);
			})
		},
		newOrder() {
			return !!(this.newOrderIDs[this.selectedOrder.orderID])
		},
		fileName() {
			if (!this.selectedOrder.companyID) return '';

			const company = this.companies[this.selectedOrder.companyID];

			return `${company.practiceName}-${this.selectedOrder.orderID}`;
		},
		ordersList() {
			const toReturn = Object.keys(this.orders).map(orderID => {
				const order = this.orders[orderID];
				const company = this.companies[order.companyID];
				return {
					order,
					company,
				}
			});

			return toReturn.sort((a, b) => {
				return a.order.date.seconds < b.order.date.seconds ? 1 : -1;
			})
		},
		shippingFormatted() {
			const toReturn = JSON.parse(JSON.stringify(this.selectedOrder.shipping));

			toReturn.price = toReturn.price ? this.formatMoney(toReturn.price) : 0;

			return toReturn
		},
		// possibleCount() {
		//   return [...Array(50)].map((_, i) => { return { text: i + 1, value: i + 1 } });
		// },
		possibleProducts() {
			return Object.keys(this.pricing).map(key => {
				return {
					text: this.pricing[key].text,
					value: key,
				};
			});
		},
		hasSelectedOrder() {
			return !!(Object.keys(this.selectedOrder).length);
		}
	},
	watch: {
		radioShipping(val) {
			this.calcShippingPrice(val);
		},
		user: {
			deep: true,
			handler(u) {
				if (u.uid) {
					this.getInitData();
				}
			}
		},
		'selectedOrder': {
			deep: true,
			handler() {
				if (!Object.keys(this.selectedOrder).length) return;
				this.calculateItems();
			},
		},
	}
};
</script>

<style lang="scss" scoped>
.form {
	display: flex;
}

.itemized {
	margin: 1rem 0 1rem 2rem;

	table {
		width: 100%;
		border-spacing: inherit;

		th {
			background: #262262;
			color: white;
			text-align: left;
			padding: 0 .5rem;
		}

		tr {
			&.blank {
				height: 27px;
			}

			&.break td {
				border-bottom: 1px solid black;
			}
		}

		td {
			&:first-child {
				border-left: 0;
			}

			&:last-child {
				border-right: 0;
			}

			padding: 0 .5rem;
			border: 1px solid #d1d3d4;

			&.right {
				text-align: right;

				&.smaller {
					font-size: 11px;
				}
			}

			.money {
				display: flex;
				justify-content: space-between;

				&.neg {
					color: red;
				}
			}
		}
	}
}

.orders {
	display: flex;
}

.list {
	width: 240px;
}

.field {
	display: flex;

	.count {
		margin-left: .5rem;
	}

	.remove {
		margin-left: .5rem;;
	}
}

.order-item {
	flex-direction: column;
	align-items: flex-start;
	min-height: inherit;

	.sub {
		font-size: 12px;
		color: gray;
	}
}

.order-card {
	padding: 1rem;
	margin-top: 2rem;
	max-width: 500px;
}

.list {
	padding: 1rem;
}

.order-actions {
	margin-bottom: 1rem;
}

.add-btn {
	margin-bottom: 1rem;
}

.section-title {
	font-weight: bold;
	margin: 1rem 0;

	&--small {
		margin-top: 0;
	}
}

</style>

const pricing = {
	'ff-.5': {
		text: 'Fluid GF™ .5cc',
		unitPrice: 1000,
		cc: .5,
		rebatePerCC: 1000,
		code: 'FF0050',
	},
	'ff-1': {
		text: 'Fluid GF™ 1cc',
		unitPrice: 2000,
		cc: 1,
		rebatePerCC: 1000,
		code: 'FF0100',
	},
	'ff-2': {
		text: 'Fluid GF™ 2cc',
		unitPrice: 4000,
		cc: 2,
		rebatePerCC: 1000,
		code: 'FF0200',
	},
	'mb-1x1': {
		text: 'Membrane Wrap™ 1x1',
		unitPrice: 330,
		cc: 1,
		rebatePerCC: 0,
		code: 'MW0101',
	},
	'mb-2x2': {
		text: 'Membrane Wrap™ 2x2',
		unitPrice: 1320,
		cc: 1,
		rebatePerCC: 0,
		code: 'MW0202',
	},
	'mb-2x3': {
		text: 'Membrane Wrap™ 2x3',
		unitPrice: 1980,
		cc: 1,
		rebatePerCC: 0,
		code: 'MW0203',
	},
	'mb-4x4': {
		text: 'Membrane Wrap™ 4x4',
		unitPrice: 5280,
		cc: 1,
		rebatePerCC: 0,
		code: 'MW0404',
	},
	'mb-4x6': {
		text: 'Membrane Wrap™ 4x6',
		unitPrice: 7920,
		cc: 1,
		rebatePerCC: 0,
		code: 'MW0406',
	},
	'mb-4x8': {
		text: 'Membrane Wrap™ 4x8',
		unitPrice: 10560,
		cc: 1,
		rebatePerCC: 0,
		code: 'MW0408',
	}
};

export { pricing };
